import React from 'react';
import { Button, InputFieldErrorMessage } from '../../common';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { multiFactorAuthentication } from '../actions';
import {
  loggedInSelector,
  loggingInLoadingSelector,
  multiFactorErrorsSelector,
} from '../selectors';
import type { ContextRouter } from 'react-router-dom';

import './MultifactorPage.css';

type RouterProps = ContextRouter;

type Props = {
  loggedIn: boolean,
  multiFactorErrors: {
    badCode: boolean,
  },
  submitMultifactor: () => void,
} & RouterProps;

class MultifactorPage extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      codeError: '',
    };
  }

  handleInputValue = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validateInput(code: string) {
    if (!code) {
      this.setState({ codeError: 'Campo obrigatório' });
    } else {
      this.setState({ codeError: '' });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {

    if (nextProps.multiFactorErrors.badCode) {
      this.setState({ codeError: 'código incorreto' });
    }
  }

  render() {
    if (this.props.loggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/fulfillments',
            state: { message: 'Você ja esta autenticado...' },
          }}
        />
      );
    }

    return (
      <div className="mfa-container">
        <h3>Validação multifatorial</h3>
        <p>
          Por favor, insira o
          <strong> código que foi enviado ao seu email</strong> para confirmar o
          seu login.
        </p>
        <form
          className="mfa-form"
          onSubmit={e => {
            e.preventDefault();
            this.props.submitMultifactor(this.state.code);
          }}
        >
          <InputFieldErrorMessage message={this.state.codeError} />
          <input
            type="text"
            name="code"
            className="login-box-input"
            placeholder="Código"
            onChange={this.handleInputValue}
          />
          <Button
            text="ENTRAR"
            buttonType="button-primary"
            loading={this.props.isLoggingIn}
            onClick={() => {}}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state),
    isLoggingIn: loggingInLoadingSelector(state),
    multiFactorErrors: multiFactorErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    submitMultifactor: code => {
      dispatch(multiFactorAuthentication(code));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MultifactorPage),
);
