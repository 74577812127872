// @flow
import { SalesFunnel } from './business';
import { Unauthorized } from './common';
import { LoginPage, LogoutPage } from './employee';
import MultifactorPage from './employee/containers/MultifactorPage';
import {
  FulfillmentListPage,
  FulfillmentPage,
  FulfillmentSortPage,
} from './fulfillments';
import RoutePage from './routes/containers/RoutePage';
import { StatisticsPage } from './statistics';

export const routes = [
  {
    path: '/login',
    component: LoginPage,
    requiredRoles: [],
  },
  {
    path: '/logout',
    component: LogoutPage,
    requiredRoles: [],
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    requiredRoles: [],
  },
  {
    path: '/fulfillments',
    exact: true,
    component: FulfillmentListPage,
    requiredRoles: [],
  },
  {
    path: '/fulfillments/sort',
    exact: true,
    component: FulfillmentSortPage,
    requiredRoles: [],
  },
  {
    path: '/fulfillments/:id',
    exact: true,
    component: FulfillmentPage,
    requiredRoles: [],
  },
  {
    path: '/statistics',
    exact: true,
    component: StatisticsPage,
    requiredRoles: [],
  },
  {
    path: '/routes',
    component: RoutePage,
    requiredRoles: [],
  },
  {
    path: '/business/funnel',
    component: SalesFunnel,
    requiredRoles: [],
  },
  {
    path: '/multifactor',
    component: MultifactorPage,
    requiredRoles: [],
  },
];
