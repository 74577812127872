// @flow

// Methods
import { login, logout, validateToken, validateMultiFactor } from './session';

const Employee = {
  login: login,
  logout: logout,
  validateToken: validateToken,
  validateMultiFactor: validateMultiFactor,
};

export default Employee;
