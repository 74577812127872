import React from 'react';
import { withRouter } from 'react-router-dom';
// Components
import { BusinessLayout } from '../../business';
import { Header, SimpleHeader } from '../../header';

type Props = {
  children: React.ReactNode,
} & RouteComponentProps;

const HeaderLayout = ({ pathname }: string) => {
  return (
    <>
      {(pathname === '/login') | (pathname === '/multifactor') ? (
        <SimpleHeader />
      ) : (
        <Header />
      )}
    </>
  );
};

const Layout = ({ children, location }: Props) => {
  const pathname = location.pathname;
  return (
    <>
      {pathname.startsWith('/business') ? (
        <div>
          <BusinessLayout children={children} />
        </div>
      ) : (
        <div>
          <HeaderLayout pathname={pathname} />
          {children}
        </div>
      )}
    </>
  );
};

export default withRouter(Layout);
