// @flow
import { createSelector } from 'reselect';
import type { StoreState } from '../types/StoreState';

export const thisStateSelector = (state: any): StoreState => state.employee;

export const loggedInSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loggedIn || false,
);

export const idSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.id,
);

export const rolesSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => {
    return !!state.user ? state.user.roles : [];
  },
);

export const isRequiredMFASelector = createSelector(
  thisStateSelector,
  (state: StoreState) => {
    return state.isRequiredMFA;
  },
);

// General Loading Related
export const loggingInLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.loggingIn,
);

export const validatingEmployeeLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.validating,
);

// Error Related
export const loginErrorsSelector = createSelector(thisStateSelector, state => ({
  badUsername: state.errors.wrongLoginUsername,
  badPassword: state.errors.wrongLoginUsername,
}));

export const multiFactorErrorsSelector = createSelector(
  thisStateSelector,
  state => ({
    badCode: state.errors.wrongMFACode,
  }),
);
