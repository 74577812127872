// @flow
import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
// Actions
import {
  loginEmployeeRequest,
  loginEmployee,
  logoutEmployee,
  validateEmployeeRequest,
  validateEmployee,
  loginEmployeeRequiredMFA,
  multiFactorEmployeeAuthentication,
  multiFactorEmployeeAuthenticationRequest,
} from '../actions';
// Types
import type { Reducer } from 'redux';
import type { Action } from '../../common';

// Employee Logged In
const loggedIn: Reducer<boolean, Action> = handleActions(
  {
    [loginEmployee]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
    [validateEmployee]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
    [logoutEmployee]: (state, action) => false,
    [multiFactorEmployeeAuthentication]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
  },
  false,
);

const id = handleActions(
  {
    [loginEmployee]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
    [logoutEmployee]: {
      next: (state, action) => null,
      throw: (state, action) => state,
    },
    [multiFactorEmployeeAuthentication]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  null,
);

const user = handleActions(
  {
    [loginEmployee]: {
      next: (state, action) =>
        action.payload.entities.employee[action.payload.result],
      throw: (state, action) => state,
    },
    [logoutEmployee]: {
      next: (state, action) => null,
      throw: (state, action) => state,
    },
    [combineActions(loginEmployee, validateEmployee)]: {
      next: (state, action) =>
        action.payload.entities.employee[action.payload.result],
      throw: (state, action) => state,
    },
    [multiFactorEmployeeAuthentication]: {
      next: (state, action) =>
        action.payload.entities.employee[action.payload.result],
      throw: (state, action) => state,
    },
  },
  null,
);

const isRequiredMFA = handleActions(
  {
    [loginEmployeeRequiredMFA]: {
      next: (state, action) =>
        action.payload.entities.mfa[action.payload.result].isRequiredMFA,
      throw: (state, action) => state,
    },
    [multiFactorEmployeeAuthentication]: {
      next: (state, action) =>
        action.payload.entities.employee[action.payload.result] && false,
      throw: (state, action) => state,
    },
  },
  false,
);

// Loading
const initialLoadingState = {
  loggingIn: false,
  validating: false,
};
const loading = handleActions(
  {
    [loginEmployeeRequest]: (state, action) => ({ ...state, loggingIn: true }),
    [loginEmployee]: (state, action) => ({ ...state, loggingIn: false }),
    [loginEmployeeRequiredMFA]: (state, action) => ({
      ...state,
      loggingIn: false,
    }),
    [multiFactorEmployeeAuthenticationRequest]: (state, action) => ({
      ...state,
      loggingIn: true,
    }),
    [multiFactorEmployeeAuthentication]: (state, action) => ({
      ...state,
      loggingIn: false,
    }),
    [validateEmployeeRequest]: (state, action) => ({
      ...state,
      validating: true,
    }),
    [validateEmployee]: (state, action) => ({ ...state, validating: false }),
  },
  initialLoadingState,
);

// Error
const initialErrorState = {
  wrongLoginUsername: false,
  wrongLoginPassword: false,
};
const errors = handleActions(
  {
    [loginEmployee]: {
      next: (state, action) => ({
        ...state,
        wrongLoginUsername: false,
        wrongLoginPassword: false,
      }),
      throw: (state, action) => ({
        ...state,
        wrongLoginUsername: true,
        wrongLoginPassword: true,
      }),
    },
    [multiFactorEmployeeAuthentication]: {
      next: (state, action) => ({
        ...state,
        wrongMFAUsername: false,
        wrongMFACode: false,
      }),
      throw: (state, action) => ({
        ...state,
        wrongMFAUsername: true,
        wrongMFACode: true,
      }),
    },
  },
  initialErrorState,
);

const reducers = combineReducers({
  loggedIn,
  id,
  user,
  isRequiredMFA,
  loading,
  errors,
});

export default reducers;
