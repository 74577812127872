// @flow
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../common';
// Apis
import Employee from '../api';
// Selectors

// Logger
import { logException, isAuthenticated } from '../../common';

// Login
export const loginEmployeeRequest = createAction('LOGIN_USER_REQUEST');
export const loginEmployee = createAction('LOGIN_USER');

export const loginEmployeeRequiredMFA = createAction('LOGIN_USER_REQUIRED_MFA');

export function login(email: string, password: string) {
  return async (dispatch: Dispatch) => {
    dispatch(loginEmployeeRequest());
    try {
      const userResponse = await Employee.login(email, password);

      if (userResponse.result === 'mfa') {
        return dispatch(loginEmployeeRequiredMFA(userResponse));
      }

      dispatch(loginEmployee(userResponse));
    } catch (err) {
      logException(err);
      dispatch(loginEmployee(err));
    }
  };
}

// 2FA
export const multiFactorEmployeeAuthenticationRequest = createAction(
  'MULTI_FACTOR_EMPLOYEE_AUTHENTICATION_REQUEST',
);
export const multiFactorEmployeeAuthentication = createAction(
  'MULTI_FACTOR_EMPLOYEE_AUTHENTICATION',
);

export function multiFactorAuthentication(code: string) {
  return async (dispatch: Dispatch) => {
    dispatch(multiFactorEmployeeAuthenticationRequest());
    try {
      const userResponse = await Employee.validateMultiFactor(code);
      dispatch(multiFactorEmployeeAuthentication(userResponse));
    } catch (err) {
      logException(err);
      dispatch(multiFactorEmployeeAuthentication(err));
    }
  };
}

// Logout user
export const logoutEmployeeRequest = createAction('LOGOUT_USER_REQUEST');
export const logoutEmployee = createAction('LOGOUT_USER');

export function logout() {
  return async (dispatch: Dispatch) => {
    dispatch(logoutEmployeeRequest());
    try {
      await Employee.logout();
      dispatch(logoutEmployee());
    } catch (err) {
      logException(err);
      // If there's an error logging out, still remove tokens
      dispatch(logoutEmployee());
    }
  };
}

// Validate token
export const validateEmployeeRequest = createAction('VALIDATE_USER_REQUEST');
export const validateEmployee = createAction('VALIDATE_USER');

export function validate() {
  return async (dispatch: Dispatch) => {
    if (!isAuthenticated()) {
      return;
    }
    dispatch(validateEmployeeRequest());
    try {
      const userResponse = await Employee.validateToken();
      dispatch(validateEmployee(userResponse));
    } catch (err) {
      logException(err);
      dispatch(validateEmployee(err));
    }
  };
}
